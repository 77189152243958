import "./SearchScreen.css"
import React, { useEffect, useState, useRef, useCallback } from "react"
import requests from "../api/requests"
import { useNavigate, useParams } from "react-router-dom"
import Nav from "../components/Nav"
import { LazyLoadImage } from "react-lazy-load-image-component"
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay"
import { Skeleton } from "@mui/material"

function SearchScreen() {
  const { title } = useParams()

  useEffect(() => {
    setContents([])
    setPage(1)
  }, [title])

  const [contents, setContents] = useState([])
  const [loading, setLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [page, setPage] = useState(1)
  const observerRef = useRef(null)
  const baseURL = process.env.REACT_APP_CMS_URL

  const loadImage = () => {
    setIsLoadingImage(false)
  }

  const navigate = useNavigate()

  const handleImageClick = (content) => {
    if (content?.status === "draft") {
      navigate("/draft", {
        state: { title: content?.title, publish_at: content?.publish_at },
      })
      return
    }

    if (content?.content_type === "episode") {
      navigate(`/episodes/${content?.id}`)
      return
    }

    if (content?.content_type === "serie") {
      navigate(`/series/${content?.id}`)
      return
    }
  }

  const fetchData = useCallback(
    async (currentPage) => {
      const response = await requests.search(currentPage, 10, title)
      if (!response.data) {
        return
      }

      setHasNextPage(response.data?.hasNextPage)
      setContents((prevContents) => [
        ...prevContents,
        ...response.data?.contents,
      ])
    },
    [title],
  )

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true)
        await fetchData(1)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    loadInitialData()
  }, [fetchData])

  useEffect(() => {
    if (!hasNextPage || loading) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1)
        }
      },
      { threshold: 1.0 },
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    const currentRef = observerRef.current

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [hasNextPage, loading])

  useEffect(() => {
    if (page > 1) {
      fetchData(page)
    }
  }, [page, fetchData])

  return (
    <div className="searchScreen">
      <Nav />
      <div className="searchScreen__contents">
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rect"
                height={"375px"}
                width={"250px"}
                animation={"wave"}
                style={{
                  minWidth: "250px",
                  minHeight: "375px",
                  display: "inline-block",
                  marginRight: "20px",
                  marginBottom: "20px",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
            ))
          : contents.length === 0 ? (
              <div className="searchScreen__noResults">
                <h1>Nenhum resultado encontrado para "{title}".</h1>
                <button
                  onClick={() => navigate("/")}
                >
                  Voltar
                </button>
              </div>
            ) : (
              contents.map((content, i) => (
                <div key={i} className="searchScreen__contents__poster">
                  {content?.content_type === "serie" && !isLoadingImage && (
                    <PlaylistPlayIcon
                      style={{
                        position: "absolute",
                        margin: 10,
                        top: 0,
                        right: 0,
                        color: "grey",
                        backgroundColor: "black",
                        fontSize: 25,
                        opacity: 0.7,
                        zIndex: 1,
                        borderRadius: 4,
                      }}
                    />
                  )}
                  <LazyLoadImage
                    effect="blur"
                    width={250}
                    height={375}
                    onLoad={loadImage}
                    src={encodeURI(
                      `${baseURL}${content?.thumbnail?.sizes?.poster?.url}`,
                    )}
                    alt={content?.thumbnail?.alt}
                    onClick={() => handleImageClick(content)}
                    style={{ borderRadius: 5 }}
                  />
                </div>
              ))
            )}
        {hasNextPage && !loading && (
          <div
            ref={observerRef}
            style={{ height: 20, backgroundColor: "transparent" }}
          />
        )}
      </div>
    </div>
  )
}

export default SearchScreen
